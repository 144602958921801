<div class="mainslider">
  <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0px;">
    <carousel>
      <slide>
        <div class="carousel-caption d-none d-md-block">
          <h3 class="carousel-h3">
            Alta tecnologia para desenvolvimento e inovação
          </h3>
          <p class="carousel-p">Utilizamos tecnologia de ponta para fornecer a melhor solução para o seu negócio.</p>
        </div>
        <img src="assets/images/banner3.jpg" alt="first slide" style="display: block; width: 100%;">
      </slide>
      <!-- <slide>
        <img src="assets/images/banner2.jpg" alt="second slide" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
          <h3 class="carousel-h3">
            CONHEÇA O SEU PÚBLICO E GARANTA MAIOR ASSERTIVIDADE
          </h3>
          <p class="carousel-p">Nossas soluções irão ajudá-lo a conhecer e alcançar o público alvo para seus produtos por meio de análises de negócio e impacto.</p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/banner3.jpg" alt="third slide" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
          <h3 class="carousel-h3">
            AUTOMATIZAÇÃO DE PROCESSOS DE EXTRAÇÃO DE DADOS
          </h3>
          <p class="carousel-p">Nossas tecnologias e estudos na área de Data Science nos permitem realizar processos com rapidez e automatizá-los para ganho de performance e tempo, o que resulta em melhores resultados para sua empresa.</p>
        </div>
      </slide> -->
    </carousel>
  </div>
</div>