/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../carousel/carousel.component.ngfactory";
import * as i3 from "../carousel/carousel.component";
import * as i4 from "ngx-bootstrap/carousel";
import * as i5 from "../shared/form-contato/formContato.component.ngfactory";
import * as i6 from "../apiServices.service";
import * as i7 from "@angular/http";
import * as i8 from "@angular/router";
import * as i9 from "../shared/form-contato/formContato.component";
import * as i10 from "ngx-captcha";
import * as i11 from "@angular/forms";
import * as i12 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-carousel", [], null, null, null, i2.View_CarouselComponent_0, i2.RenderType_CarouselComponent)), i1.ɵdid(1, 114688, null, 0, i3.CarouselComponent, [], null, null), i1.ɵprd(256, null, i4.CarouselConfig, i3.ɵ0, []), (_l()(), i1.ɵeld(3, 0, null, null, 1, "section", [["class", "why_register text_center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-formContato", [["text", "Entre em contato conosco"]], null, null, null, i5.View_FormContatoComponent_0, i5.RenderType_FormContatoComponent)), i1.ɵprd(512, null, i6.ApiServices, i6.ApiServices, [i7.Http, i8.Router]), i1.ɵdid(7, 114688, null, 0, i9.FormContatoComponent, [i10.ReCaptchaV3Service, i11.FormBuilder, i6.ApiServices], { text: [0, "text"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "Entre em contato conosco"; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i12.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i12.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
