<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Quem Somos</h1>
        <div class="sub_heading_page">Tecnologia e inovação</div>
      </div>
    </div>
  </div>
</section>

<!-- ========= Inner Page Banner Section End ========= --> 

<!-- ========= About US Section Start ========= -->

<section class="support_features">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <h3>Programar Devs</h3>
            
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="support_features_inner">
            <h5 class="section_sub_heading text-center">Quem Somos</h5>
            <p>Somos uma empresa dedicada a fornecer soluções tecnológicas
              inovadoras e personalizadas. Especializada em outsourcing de
              desenvolvedores, nossa missão é conectar empresas a talentos
              excepcionais na área de desenvolvimento de software,
              atendendo a uma ampla gama de necessidades tecnológicas.</p>
              <p>
              Com uma equipe de profissionais altamente qualificados e apaixonados
              por tecnologia, estamos comprometidos em entregar resultados que
              superam expectativas e impulsionam o sucesso dos nossos clientes.</p>
            <h5 class="section_sub_heading text-center">Nossa Equipe</h5>
            <p>Nossa equipe é formada por profissionais que prezam pela qualidade e alta disponibilidade, utilizando-se
                de pontos de apoio em vários locais do mundo. Contamos com analistas de dados, programadores,
                especialistas em infraestrutura, especialistas em projetos, dentre outros que se comprometem com a missão
                de criar serviços e produtos para esse novo mundo digital.</p>
          </div>
          </div>
      </div>
    </div>
  </section>
