import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-outsourcing",
  templateUrl: "./outsourcing.component.html",
  styleUrls: ["./outsourcing.component.css"],
})
export class OutsourcingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
