import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'integracao-de-sistemas',
  templateUrl: './integracao-de-sistemas.component.html',
  styleUrls: ['./integracao-de-sistemas.component.css']
})
export class IntegracaoDeSistemasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
