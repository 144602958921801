<footer class="footer noprint">
  <div class="f_outr_section">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="f_txt_heading">
            <h4>Programar Devs</h4>
            <ul>
                <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
              <li><a href="javascript:void(0)" routerLink="/quem-somos">Quem somos</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="f_txt_heading">
            <h4>Soluções</h4>
            <ul>
              <li><a href="javascript:void(0)" routerLink="/solucoes/bi-dashboards">Business Intelligence</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/software-on-demand">Software on demand</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/integracao-sistemas">Integração de sistemas</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/aplicativos">Aplicativos</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/outsourcing">Outsourcing</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/gestao-de-riscos">Gestão de Riscos Financeiros</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/seguranca-e-compliance">Prevenção à Fraude e Compliance</a></li>
              <!-- <li><a href="javascript:void(0)" routerLink="/solucoes/gestao-de-campanhas">Gestão de Campanhas WhatsApp e SMS</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="f_txt_heading">
            <h4>Área do Cliente</h4>
            <ul>
              <li><a href="javascript:void(0)" routerLink="/painel">Login</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="f_txt_heading">
              <h4>Contato</h4>
              <ul>
                <li><a href="javascript:void(0)" routerLink="/contato">Entre em contato</a></li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="copy-right">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="coright-text">Copyright © {{ano}} Programar Devs. Todos direitos reservados.</div>
        </div>
        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="copyright-menu">
            <ul>
              <li><a href="javascript:void(0)" routerLink="/politica-de-privacidade">Termos de Uso e Política de Privacidade</a></li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</footer>

<!-- <table>
  <tr>
    <th>Documento</th>
    <th>Hora inicio</th>
    <th>Hora fim</th>
    <th>Msg</th>
  </tr>
  <tr *ngFor="let linha of json">
    <td>{{linha.input.cnpj}} {{linha.input.cpf}}</td>
    <td>{{linha.startAt | date:'dd/MM/yyyy HH:mm:ss'}}</td>
    <td>{{linha.endAt | date:'dd/MM/yyyy HH:mm:ss'}}</td>
    <td>{{linha.output.dados.score.msg}}</td>
  </tr>
</table> -->