<section class="contact_form">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>{{ text }}</h3>
        </div>
      </div>
      <div class="col-md-8 col-sm-12 col-xs-12">
        <form [formGroup]="formContato" class="contact-form" (submit)="enviarEmail()">
          <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
            <span [innerHTML]="msgCadastro"></span>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <span class="ctext">
              <input formControlName="nome" type="text" placeholder="Nome Completo" class="c_text"  value="" name="nome" required>
            </span>
            <small *ngIf="formContato.get('nome').errors?.required" class="text-danger d-block mt-2">
              Nome completo é obrigatório!
            </small>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <span class="ctext">
              <input formControlName="telefone" mask='(00) 00000-0000' type="text" placeholder="Telefone" class="c_text"  value="" name="telefone" required>
            </span>
            <small *ngIf="formContato.get('telefone').errors?.required" class="text-danger d-block mt-2">
              Telefone é obrigatório (somente números)!
            </small>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <span class="ctext">
              <input formControlName="assunto" type="text" placeholder="Assunto" class="c_text"  value="" name="assunto" required>
            </span>
            <small *ngIf="formContato.get('assunto').errors?.required" class="text-danger d-block mt-2">
              Assunto é obrigatório!
            </small> 
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <span class="ctext">
              <input formControlName="email" type="email" placeholder="Email" class="c_text"  value="" name="email" required>
            </span>
            <small *ngIf="formContato.get('email').errors?.required" class="text-danger d-block mt-2">
              Email é obrigatório!
            </small>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <span class="ctext">
              <textarea formControlName="mensagem" placeholder="Mensagem (Máximo de 500 caractéres)" class="c_texta"  name="mensagem" required> </textarea>
            </span>
            <small *ngIf="formContato.get('mensagem').errors?.required" class="text-danger d-block mt-2">
              Mensagem é obrigatório!
            </small>
          </div>
          <button type="submit" class="contact_btn primary_button" value="Enviar Mensagem">Enviar Mensagem</button>
        </form>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="c_address_box text_center"> <i class="fa fa-map-marker-alt address-icon" aria-hidden="true"></i>
          <h5 class="section_sub_heading"></h5>
          <p>Al. Rio Negro, 877 - Alphaville Comercial, Barueri - SP, 06454-000</p>
          <!-- <div class="contact_social">
            <ul>
              <li><a target="_blank" href="javascript:void(0)"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
              <li><a target="_blank" href="javascript:void(0)"><i class="fab fa-google-plus" aria-hidden="true"></i></a></li>
              <li><a target="_blank" href="javascript:void(0)"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
              <li><a target="_blank" href="javascript:void(0)"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>